<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll-nav ref="nav" current="基本信息" v-if="detail">
        <cube-scroll-nav-panel key="基本信息" label="基本信息">
          <inputItem title="采购编码" v-model="detail.code" :disabled="true"></inputItem>
          <inputItem title="下单时间" :initialContent="formatDate(detail.purchase_time, 'yyyy-MM-dd hh:mm')" :disabled="true"></inputItem>
          <inputItem title="业务归属" v-model="detail.sale_owner" :disabled="true"></inputItem>
          <inputItem title="业务部门" v-model="detail.dept" :disabled="true"></inputItem>
          <inputItem title="供应商名" v-model="detail.supplier_name" :disabled="true"></inputItem>
          <inputItem title="采购人员" v-model="detail.handler_name" :disabled="true"></inputItem>
          <inputItem title="采购备注" v-model="detail.remark" :disabled="true"></inputItem>
          <inputItem v-if="detail.sale_code" title="销售单号" v-model="detail.sale_code" :disabled="true" class="margin-top-10"></inputItem>
          <inputItem v-if="detail.project_name" title="项目名称" v-modelt="detail.project_name" :disabled="true"></inputItem>
          <inputItem v-if="detail.customer_name" title="客户名称" v-model="detail.customer_name" :disabled="true"></inputItem>
          <inputItem v-if="detail.sale_user" title="业务代表" v-model="detail.sale_user" :disabled="true"></inputItem>
          <inputItem v-if="detail.sale_remark" title="销售备注" v-model="detail.sale_remark" :disabled="true"></inputItem>
          <itemTotal :total="total"></itemTotal>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="采购明细" label="采购明细">
          <div class="mobile-detail-list">
            <div class="list-item" v-for="(item, index) in items" :key="index">
              <div class="item-header">
                <div class="item-index">商品{{ index + 1 }}</div>
              </div>
              <div class="item-line">
                <div class="item-lable"> 商品名称 </div>
                <div class="item-value"> {{ item.goods_name }} </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 采购数量 </div>
                  <input class="input-right" v-model="item.num" />
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 采购单价 </div>
                  <input class="input-right" v-model="item.price" />
                </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 采购金额 </div>
                  <input class="input-right" v-model="item.total" />
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 采购备注 </div>
                  <div class="input-left"> {{ item.remark }} </div>
                </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 销售金额 </div>
                  <div class="input-right"> {{ item.sale_total }} </div>
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 销售备注 </div>
                  <div class="input-right"> {{ item.sale_item_remark }} </div>
                </div>
              </div>
              <div class="item-box">
                <div class="item-box-half">
                  <div class="item-lable"> 合计毛利 </div>
                  <div class="input-right"> {{ item.profit_total }} </div>
                </div>
                <div class="item-box-half">
                  <div class="item-lable"> 总毛利率 </div>
                  <div class="input-right"> {{ item.profit_rate }} </div>
                </div>
              </div>
            </div>
          </div>
        </cube-scroll-nav-panel>
        <cube-scroll-nav-panel key="审批流程" label="审批流程" v-if="flows && flows.flowLogs && flows.flowLogs.length > 0">
          <div class="mobile-detail-step">
            <Steps :current="flows.step" :status="flows.status" direction="vertical">
              <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName" :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
            </Steps>
          </div>
        </cube-scroll-nav-panel>
      </cube-scroll-nav>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
  </div>
</template>

<script>
import { GetPurchase, AuditPurchase, CancelPurchase } from "@/api";
import { purchaseState } from "@/utils/state.js";
import { formatDate, formatMoney } from "@/utils/format.js";
import { loadDetailAction, auditAction, cancelAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      items: [],
      flows: [],
      buttons: [],
      total: {
        total: null,
        cost: null,
        profit: null,
        rate: null,
        comix_tariff: null,
        comix_price: null,
      }
    };
  },
  methods: {
    purchaseState: purchaseState,
    formatDate: formatDate,
    formatMoney: formatMoney,
    setTotal: function () {
      var tot = this.total;
      tot.total = 0;
      tot.cost = 0;
      tot.profit = 0;
      tot.rate = 0;
      tot.comix_tariff = 0;
      this.items.forEach(function (item) {
        tot.comix_tariff = Number(item.comix_tariff);
        tot.total += Number(item.sale_total);
        tot.cost += Number(item.total);
      });
      tot.comix_price = (tot.total * tot.comix_tariff) / 100;
      tot.profit = tot.total - tot.cost - tot.comix_price;
      tot.rate = tot.total == 0 ? 0 : ((tot.profit * 100) / tot.total).toFixed(2);
    },
    buttonClick: function (title) {
      switch (title) {
        case "审核通过":
          this.auditPurchase(true, "同意");
          break;
        case "审核拒绝":
          this.auditPurchase(false, "");
          break;
        case "撤回":
          this.cancelPurchase();
          break;
      }
    },
    auditPurchase: function (pass, memo) {
      auditAction(this, AuditPurchase, this.id, pass, memo);
    },
    cancelPurchase: function () {
      cancelAction(this, CancelPurchase, this.id);
    },
    dataRequest: function () {
      loadDetailAction(this, GetPurchase, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
        this.items = data.list[0].items;
        this.flows = data.list[0].flows;
        this.buttons = data.list[0].buttons;
        this.setTotal();
        this.$nextTick(() => { this.$refs.nav.refresh(); });
      });
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>
